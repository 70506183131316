/* tslint:disable */
/* eslint-disable */
/**
 * Empower API
 * Empowers the Empower App
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicStatus,
    TopicStatusFromJSON,
    TopicStatusFromJSONTyped,
    TopicStatusToJSON,
} from './TopicStatus';

/**
 * 
 * @export
 * @interface TopicPreview
 */
export interface TopicPreview {
    /**
     * 
     * @type {number}
     * @memberof TopicPreview
     */
    id?: number;
    /**
     * 
     * @type {TopicStatus}
     * @memberof TopicPreview
     */
    status?: TopicStatus;
    /**
     * 
     * @type {string}
     * @memberof TopicPreview
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TopicPreview
     */
    subtitle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TopicPreview
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TopicPreview
     */
    endDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TopicPreview
     */
    liveTime?: Date | null;
}

export function TopicPreviewFromJSON(json: any): TopicPreview {
    return TopicPreviewFromJSONTyped(json, false);
}

export function TopicPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : TopicStatusFromJSON(json['status']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'liveTime': !exists(json, 'liveTime') ? undefined : (json['liveTime'] === null ? null : new Date(json['liveTime'])),
    };
}

export function TopicPreviewToJSON(value?: TopicPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': TopicStatusToJSON(value.status),
        'title': value.title,
        'subtitle': value.subtitle,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'liveTime': value.liveTime === undefined ? undefined : (value.liveTime === null ? null : value.liveTime.toISOString()),
    };
}

