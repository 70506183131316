import { createContext, useContext, useEffect, useState } from 'react';

import { Configuration, AuthApi } from 'api';

import config from 'components/Config';

const auth = localStorage.getItem('auth');

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [token, setToken] = useState(auth ? JSON.parse(auth).token : null);
  const [validTo, setValidTo] = useState(auth ? new Date(JSON.parse(auth).validTo).getTime() : null);
  const [user, setUser] = useState(auth ? JSON.parse(auth).user : null);
  const [isAuthenticated, setIsAuthenticated] = useState(validTo > Date.now());

  const configuration = new Configuration({
    basePath: config.API_URL,
  });

  useEffect(() => {
    setIsAuthenticated(validTo > Date.now());
  }, [token, validTo]);

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify({ token, validTo, user }));
  }, [token, validTo, user]);

  const authApi = new AuthApi(configuration);

  const login = (formData, callback) => {
    return authApi
      .authLoginPost({
        loginRequest: formData,
      })
      .then(resp => {
        const { token, validTo, user } = resp;

        localStorage.setItem('auth', JSON.stringify(resp));
        setIsAuthenticated(validTo > Date.now());
        setUser(user);
        setValidTo(validTo);
        setToken(token);
        callback(user.role);
      })
      .catch(error => {
        callback(null, error);
      });
  };

  const logout = callback => {
    localStorage.removeItem('auth');
    setToken(null);
    setValidTo(null);
    setUser(null);
    callback();
  };

  const value = {
    user,
    setUser,
    isAuthenticated,
    token,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
